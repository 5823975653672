.about-us,
.welcome-block,
.proposal-steps {
  h2 {
    @include respTextPhone();
    @include respTextIphone();
  }
}

.welcome-block {
  p {
    @include respondto(onlyDesktop) {
      padding: 0 14rem;
    }

    @include respondto(tablet) {
      padding: 0 15rem;
    }

    @include respondto(phone) {
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
}

.note-block {
  p {
    padding: 6rem 0 10rem 0;

    @include respondto(phone) {
      font-size: 1.6rem;
      padding: 5rem 0;
    }
  }
}