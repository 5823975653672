/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../bower_components/bootstrap-sass-official/assets/stylesheets/_bootstrap.scss";
@import "../bower_components/slick-carousel/slick/slick.scss";
// endbower

// bower:css
@import "../bower_components/slick-carousel/slick/slick.css";
@import "../js/vendor/jqvmap.css";
// endbower


/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "_core/_1-variables.scss";
@import "_core/_2-mixins.scss";
@import "_core/_buttons.scss";
@import "_core/_flex.scss";
@import "_core/_fonts.scss";
@import "_core/_general-classes.scss";
@import "_core/_global.scss";
@import "_core/_icons.scss";
@import "partials/_1-header.scss";
@import "partials/_2-footer.scss";
@import "partials/_proposal-steps.scss";
@import "partials/_slick-carousel.scss";
@import "pages/Homepage/_about-us.scss";
@import "pages/Homepage/_centered-blocks.scss";
@import "pages/Homepage/_hero-block.scss";
@import "pages/Homepage/_map.scss";
@import "pages/Homepage/_slider.scss";
@import "pages/Homepage/_submission.scss";
@import "pages/Homepage/_submitting-application.scss";
// endinjector