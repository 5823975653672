// $z-index: 5;

/**
 * BCell colors
 */
$palettes: (
  white:(
   base: #fff
  ),
  black:(
   base: #000,
  ),
  blue:(
   base: #283055,
   light: #cde9ff,
   medium: #0067ac,
   dark1: #009eff,
   dark2: #0071bb
  ),
  red: (
    base: #ad0029
  ),
  grey: (
    base: #383838,
    light: #ededed,
    light2: #959191
  ),
  orange: (
    base: #ff9933
  ),
  purple: (
    base: #a00769
  )
);

/**
 * Media Queries
 */
$breakpoints: (
  largeDesktop: '(min-width: 1920px)',
  desktop: '(min-width: 1200px)',
  desktopSmall: '(min-width: 992px) and (max-width: 1199px)',
  onlyDesktop: '(min-width: 992px)',
  maxTablet: '(max-width: 991px)',
  tablet: '(min-width: 768px) and (max-width: 991px)',
  phone: '(max-width: 767px)',
  phoneSmall: '(max-width: 480px)',
  iphone5: '(max-width: 350px)',

  desktopAndTablet: '(min-width: 768px)'
);
