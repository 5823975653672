.button {
  border: none;
  color: palette(white);
  display: inline-block;
  font-family: 'lato-bold';
  font-size: 1.8rem;
  padding: 1rem 1.5rem;
  text-transform: uppercase;

  @include transition();

  &-red {
    background-color: palette(red);

    &:hover,
    &:focus {
      background-color: darken(palette(red), 10%);
      color: palette(white);
      text-decoration: none;
    }
  }

  &-white {
    background-color: palette(white);
    border: 1px solid palette(red);
    padding: .6rem 1rem;
    text-align: center;
    width: 19rem;

    &:hover,
    &:focus {
      background-color: palette(white);
      border: 1px solid palette(black);
    }
  }

  &-black {
    background-color: palette(black);
    padding: .6rem 1rem;

    &:hover,
    &:focus {
      background-color: palette(grey, dark);
    }
  }

  &-sm {
    padding: .8rem 1rem .5rem 1rem;
  }

  &-xs {
    max-width: 85%;
    padding: .2rem 1rem;
  }

  &[disabled] {
    background-color: palette(gray, light);
    color: palette(gray)
  }

  &-editable {
    float: right;
    font-size: 1.4rem;
    line-height: 1em;
    margin-bottom: 3rem;
    padding: .5rem 1rem;

    &:before {
      font-family: 'bcell-icons';
      font-size: 1.4rem;
      margin-right: 1rem;
    }
  }

  &-edit {
    &:before {
      content: '\e907';
    }
  }

  &-update {
    &:before {
      content: '\ea1c';
    }
  }
}