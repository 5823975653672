.table {
  border: none;
  margin: 0;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 5px solid palette(white);
  color: palette(blue);
}

.table > thead > tr > th,
.table > thead > tr > td {
  padding: 3rem;

  @include respondto(maxTablet) {
    padding: 2rem;
  }
}

.table > tbody > tr > th,
.table > tbody > tr > td {
  padding: 1.5rem 2rem;
  vertical-align: middle;

  @include respondto(maxTablet) {
    font-size: 1.3rem;
    padding: 1.5rem;
  }
}

.submission-block {
  @include respondto(onlyDesktop) {
    margin-bottom: 7rem;
  }

  h2 {
    @include respTextPhone();
    @include respTextIphone();
  }

  .custom-table {
    border: none;
    @include respondto(onlyDesktop) {
      padding: 0 6rem;
    }

    @include respondto(tablet) {
      margin-bottom: 6rem;
    }

    @include respondto(phone) {
      margin-bottom: 5rem;
    }

    &-sm {
      margin-top: 6rem;

      @include respondto(phone) {
        margin-top: 3rem;
      }

      .table > thead > tr > th,
      .table > thead > tr > td {
        @include respondto(phone) {
          font-size: 1.4rem;
        }
      }

      .table > thead > tr > th,
      .table > thead > tr > td,
      .table > tbody > tr > th,
      .table > tbody > tr > td {
        @include respondto(phone) {
          padding: 1rem;
          width: 33%;
        }
      }

      tr {
        td {
          &:first-child {
            @include respondto(onlyDesktop) {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }

  th,
  td {
    background-color: palette(blue, light);
  }

  th {
    @include respondto(maxTablet) {
      font-size: 1.6rem;
    }
  }
}