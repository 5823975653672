.aboutUs-carousel {
  margin-bottom: 16rem;

  @include respondto(phone) {
    margin-bottom: 12rem;
  }

  &.slick-initialized {
    .slick-slide {
      padding: 0 10rem;

      @include respondto(maxTablet) {
        padding: 0;
      }

      p {
        line-height: 3rem;

        @include respondto(phone) {
          font-size: 1.8rem;
          line-height: 2.4rem;
        }
      }

      img {
        @include respondto(phone) {
          height: 100%;
        }
      }

      &.slick-item {
        display: flex!important;
        margin: 0;
        @include flex($align: center);

        &:hover,
        &:focus {
          outline: none;
        }

        @include respondto(desktopSmall) {
          height: 40rem;
        }

        @include respondto(maxTablet) {
          @include flex($direction: column);
        }
      }
    }
  }

  .info-block {
    @include respondto(maxTablet) {
      @include flex($order:2);
    }
  }

  .img-block {
    @include respondto(maxTablet) {
      margin-bottom: 3rem;
      order: 1;
    }
  }


  .slick-arrow {
    color: palette(blue, medium);
    padding: 0 1rem;

    &:hover,
    &:focus {
      color: palette(blue);
      outline: none;
    }

    @include respondto(maxTablet) {
      display: none!important;
    }
  }
}