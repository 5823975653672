.header-image {
  @include respondto(onlyDesktop) {
    height: 75rem;
  }

  @include respondto(maxTablet) {
    background-position: 50% 0;
  }

  @include respondto(tablet) {
    height: 70rem;
  }

  @include respondto(phone) {
    height: 40rem;
  }

  .container {
    @include respondto(phone) {
      margin: 0 1.5rem;
    }
  }
}

.welcome-box {
  margin-left: 1.5rem;
  padding: 4.5rem 4rem;
  width: 58rem;

  @include respondto(onlyDesktop) {
    margin-top: 22vh;
  }

  @include respondto(maxTablet) {
    text-align: center;
  }

  @include respondto(tablet) {
    margin: 20rem auto 0 auto;
  }

  @include respondto(phone) {
    margin: 6rem auto 0 auto;
    padding: 3.5rem 2rem;
    width: 100%;
  }

  &-envision {
    @include respondto(onlyDesktop) {
      position: absolute;
      bottom: 15rem;
    }

    @include respondto(tablet) {
      position: absolute;
      bottom: 15rem;
    }
  }

  h1 {
    @include respondto(phone) {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }

    @include respondto(iphone5) {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
}

.logo-block {
  bottom: 0;
  padding: 1rem;

  img {
    &:first-of-type {
      margin-right: 4rem;

      @include respondto(phone) {
        margin-right: 1rem;
      }
    }

    &:last-of-type {
      margin-left: 4rem;

      @include respondto(phone) {
        margin-left: 1rem;
      }
    }

    @include respondto(phone) {
      max-width: 48%;
      height: 4rem;
    }

    @include respondto(iphone5) {
      max-width: 45%;
    }
  }
}



