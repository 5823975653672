.proposal-steps {
  h3 {
    margin: 3.5rem 0 2.5rem 0;

    @include respondto(desktopSmall) {
      font-size: 2.6rem;
    }

    @include respondto(tablet) {
      font-size: 2rem;
    }

    @include respondto(phone) {
      font-size: 2.2rem;
    }
  }

  ul {
   padding: 0 0 0 2.5rem;

    li {
      margin-bottom: 1.5rem;

      @include respondto(tablet) {
        font-size: 1.6rem;
      }
    }
  }
}