html {
  height: 100%;
  -ms-overflow-style: scrollbar;
}

html,
body {
  font-family: 'lato';
  font-size: 62.5%;

  @include respondto(desktopSmall) {
    font-size: 57.5%;
  }

  @include respondto(tablet) {
    font-size: 55%;
  }
}

body {
  font-size: 1.8rem;
  line-height: 1.8rem;
  transition: transform .5s ease-in-out;
}

p,
ul li {
  color: palette(blue);
  font-size: 1.8rem;
  line-height: 2.4rem;
}

a {
  background-color: inherit;
  color: palette(red);

  @include transition();

  &:hover,
  &:focus {
    background-color:inherit;
    color: palette(blue, light);
  }
}

iframe {
  border: 0;
}

h1,
h2,
h3,
h4,
a,
span,
li,
div,
button {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1.3em;
}

h1,
h2,
h3,
h4,
p,
span,
a {
  letter-spacing: .5px;
}

h1,
h2,
h3,
h4 {
  color: palette(blue);
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

.form-control {
  border-radius: 0;
}
