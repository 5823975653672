// Flex
.display-flex {
  display: flex;
}

.display-inline-flex {
  display: inline-flex;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-column--sm {
  @include respondto(tablet) {
    flex-direction: column;
  }
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-baseline {
  align-items: baseline;
}

.align-self-center {
  align-self: center;
}

.align-self-end {
  align-self: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-around {
  justify-content: space-around;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-auto {
  flex: 1 1 auto;
}

/*------------------------------------------------------------------
Mobile
------------------------------------------------------------------*/

.layout-row--xs {
  @include respondto(phone) {
    flex-direction: row;
  }
}

.layout-column--xs {
  @include respondto(phone) {
    flex-direction: column;
  }
}

.justify-content-center--xs {
  @include respondto(phone) {
    justify-content: center;
  }
}

.justify-content-center--sm {
  @include respondto(tablet) {
    justify-content: center;
  }
}

.align-items-center--xs {
  @include respondto(phone) {
    align-items: center;
  }
}

.align-items-center--sm {
  @include respondto(tablet) {
    align-items: center;
  }
}

.align-items-initial--xs {
  @include respondto(phone) {
    align-items: initial;
  }
}