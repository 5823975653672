.featured-promos,
.popular-pizza,
.posts-listing,
.press-releases,
.press-releases-listing,
.posts {
  .slick-next,
  .slick-prev  {
    display: none!important;
  }
}

.slick-dots {
  @include respondto(desktopAndTablet) {
    display: none!important;
  }
}

// General
.slick-next,
.slick-prev {
  background-color: transparent;
  border: none;
  font-family: "bcell-icons";
  font-size: 6.2rem;
  height: 7rem;
  overflow: hidden;
  position: absolute;
  top: 45%;
  width: 5rem;
  z-index: 9;
}

.slick-next {
  right: 1rem;

  &:before {
    content: "\e904";
  }
}

.slick-prev {
  left: 1rem;

  &:before {
    content: "\e905";
  }
}

/* Dots */
.slick-dots {
  bottom: -9rem;
  display: block!important;
  left: 0;
  list-style: none;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;

  @include respondto(phone) {
    bottom: -6.5rem;
  }

  li {
    cursor: pointer;
    display: inline-block;
    margin: 0 .5rem;
    padding: 0;
    position: relative;
    height: 1.5rem;
    width: 1.5rem;

    button {
      border: 0;
      background: transparent;
      color: transparent;
      cursor: pointer;
      font-size: 0px;
      height: 1.5rem;
      line-height: 0px;
      outline: none;
      padding: .5rem;
      width: 1.5rem;

      &:hover,
      &:focus {
        outline: none;

        &:before {
          color: palette(blue);
        }
      }
      &:before {
        color: palette(blue, medium);
        content: '•';
        font-size: 4.5rem;
        left: 0;
        line-height: 1.5rem;
        height: 1.5rem;
        position: absolute;
        text-align: center;
        top: 0;
        width: 1.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
        color: palette(blue);
    }
  }
}

