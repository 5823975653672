footer {
  background-color: palette(grey);
  padding: 3.5rem 0 7rem 0;

  @include respondto(phone) {
    padding: 3rem 0 6rem 0;
  }

  p,
  a,
  span {
    color: palette(white);
    font-size: 1.4rem;

    @include respondto(maxTablet) {
      margin: 0;
    }
  }

  span {
    margin: 0 .5rem;
  }

  a {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}