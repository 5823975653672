.submitting-application {
  h2 {
    margin-bottom: 3.5rem;

    @include respTextPhone();
    @include respTextIphone();
  }

  span {
    color: palette(blue, medium);
  }
}