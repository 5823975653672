@include font-face('bcell-icons', '../resources/icons/bcell-icons');

// LibreFranklin
@include font-face('lato', '../resources/fonts/lato/lato-regular');
@include font-face('lato-black', '../resources/fonts/lato/lato-black');
@include font-face('lato-bold', '../resources/fonts/lato/lato-bold');


$font-sizes: (14, 18, 20, 22, 25, 28, 32, 40);

@each $font-size in $font-sizes {
  .font-#{ $font-size } {
    font-size: #{ ($font-size / 10) + 'rem' };
  }
}

@each $font-size in $font-sizes {
  .font-#{ $font-size }--xs {
    @include respondto(phone) {
      font-size: #{ ($font-size / 10) + 'rem' };
    }
  }
}

// Flex dimensions
@each $dimmension in 14, 18, 20, 25, 32, 40 {
  .flex-#{$dimmension} {
    @include flex-percentage(#{ $dimmension + '%'});

    @include respondto(phone) {
      @include flex-percentage();
    }
  }
}

.flex-100--xs {
  @include respondto(phone) {
    @include flex-percentage();
  }
}
