.map-container {
  height: 60rem;
  margin-bottom: 12rem;

  @include respondto(desktop) {
    width: 1100px;
  }

  @include respondto(desktopSmall) {
    width: 900px;
  }

  @include respondto(phone) {
    height: 25rem;
    margin-bottom: 0;
  }
}